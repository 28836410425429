@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

@media screen and (max-width: 500px) {
  #menu .input-email,
  .btn-subscribe {
    float: none;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-top: 20px;
  }

  #menu .input-email {
    width: 200px;
  }

  .btn-subscribe {
    width: 100px;
    padding-left: 13px;
    margin-left: 10px;
    vertical-align: baseline;
  }

  #about .code-box {
    height: auto;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  #pricing .price-box {
    width: auto;
    flex-direction: column;
  }

  #pricing .price-box .third-price {
    margin-top: 10px;
  }

  #contact .content {
    width: auto;
    height: auto;
    flex-direction: column;
  }

  #location .content {
    width: auto;
    height: auto;
    flex-direction: column;
  }

  #features .feature-box {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #features .feature-item {
    margin-top: 10px;
  }

  #features .section-title p {
    padding-left: 10px;
    padding-right: 10px;
  }

  #about .section-title {
    width: auto;
    height: auto;
  }

  #about p {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  #about .description {
    width: auto;
    height: auto;
  }

  #location .content .info p {
    width: 100%;
  }

  #location .content .info {
    width: 100%;
    height: auto;
    margin-top: 30px;
    height: 210px;
  }

  #location .content .info .items {
    width: 100%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  #location #map {
    width: 100%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  #contact .content .info p {
    width: 100%;
  }

  #contact .content .info .items {
    width: 100%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  #contact .content .info {
    width: 100%;
    height: 210px;
    margin-top: 30px;
  }

  #testimonials .testimonial-container {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  #testimonials .testimonial-container .testimonial-item,
  #testimonials .testimonial-container .feature-item {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  #testimonials p {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
  }

  #features .section-title {
    width: 100%;
  }

  .intro {
    height: calc(100vh + 320px);
  }

  .intro .intro-bg1 {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    float: none;
  }

  header .intro-text {
    margin-left: auto;
    margin-top: 200px;
    width: 100%;
  }

  .intro h1, .intro p {
    text-align: center;
    width: 100%;
  }

  .btn-audit {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 50px;
  }

  .ReactModal__Content {
    width: 400px;
  }
}